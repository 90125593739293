<template>
    <el-main>
        <el-form class="el-form-search" label-width="120px">
            <el-form-item label="订单编号：">
                <el-input size="small" placeholder="请输入订单编号" v-model="formData.order_sn"></el-input>
            </el-form-item>
            <el-form-item label="用户手机：">
                <el-input size="small" placeholder="请输入买家手机号" v-model="formData.mobile"></el-input>
            </el-form-item>
            <el-form-item label="下单时间：">
                <el-date-picker v-model="formData.time" size="small" type="daterange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="就餐方式：">
                <el-select v-model="formData.type" placeholder="请选择" class="nomalwidth">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="堂食" :value="1"></el-option>
                    <el-option label="打包" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订单状态：">
                <el-select v-model="formData.status" placeholder="请选择" class="nomalwidth">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已取消" :value="-10"></el-option>
                    <el-option label="待付款" :value="0"></el-option>
                    <el-option label="待核销" :value="10"></el-option>
                    <el-option label="已完成" :value="20"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="45px">
                <el-button size="small" type="primary" @click="getOrder(1)">搜索</el-button>
                <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <!-- 商品列表 -->
        <div class="order_list">
            <table width="1600">
                <thead>
                    <tr>
                        <th>订单信息</th>
                        <th>菜品单价</th>
                        <th>菜品金额</th>
                        <th>餐位费用</th>
                        <th>打包费用</th>
                        <th>配送费用</th>
                        <th>优惠</th>
                        <th>订单金额</th>
                        <th>就餐方式</th>
                        <th>用户手机</th>
                        <th>下单时间</th>
                        <th>订单状态</th>
                        <th>取餐码</th>
                        <th>操作</th>
                    </tr>
                    <tr style="height: 10px"></tr>
                </thead>
                <tbody v-if="!list.length">
                    <tr style="background: #fff">
                        <td colspan="13">
                            <No />
                        </td>
                    </tr>
                </tbody>
                <tbody v-for="item in list" :key="item.id">
                    <tr>
                        <td colspan="13" class="order_data_top">
                            <div class="td_box">
                                <div class="order_info">
                                    <div class="order_no">订单编号:{{ item.order_sn }}</div>
                                    <div>桌号：{{ item.table_no }}</div>
                                </div>
                                <div v-if="item.remark" class="order_info">
                                    <div class="order_no">备注</div>
                                    <el-popover placement="top-start" width="200" trigger="hover" :content="item.remark">
                                        <i class="el-icon-info" slot="reference"></i>
                                    </el-popover>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="goods_info">
                            <div v-for="goods in item.ordergoods" :key="goods.id">
                                <el-image :src="goods.cuisine_picture" class="goods_img"></el-image>
                                <div class="goods_name">
                                    <div class="name">{{ goods.cuisine_name }}</div>
                                    <div v-if="goods.sku_name" style="margin-top: 5px">规格:{{ goods.sku_name }}</div>
                                </div>
                            </div>
                        </td>
                        <td class="p_nums">
                            <div v-for="goods in item.ordergoods" :key="goods.id">
                                <div>￥{{ goods.price }}</div>
                                <div>x{{ goods.goods_num }}</div>
                            </div>
                        </td>
                        <td class="t_price">
                            <div>￥{{ getCateringPrice(item.ordergoods).toFixed(2) }}</div>
                        </td>
                        <td class="t_price">
                            <template v-if="item.type == 1">
                                <div>￥{{ (item.table_fee / item.num).toFixed(2) }}</div>
                                <div>x{{ item.num }}</div>
                            </template>
                            <div v-else>--</div>
                        </td>
                        <td class="t_price">
                            <div v-if="item.type == 2 || item.type==3">￥{{ item.pack_fee ? item.pack_fee : 0 }}</div>
                            <div v-else>--</div>
                        </td>
                         <td class="t_price">
                            <div v-if="item.type==3">￥{{ item.postage_fee ? item.postage_fee : 0 }}</div>
                            <div v-else>--</div>
                        </td>
                        <td class="t_price">
                            <div>￥{{ item.reduce_price }}</div>
                        </td>
                        <td class="t_price">
                            <div>￥{{ item.total_price }}</div>
                        </td>
                        <td class="t_price">
                            <div>{{ item.type == 1 ? '堂食' : (item.type == 3 && item.is_self == 0) ? '商家自配送' : (item.type ==
                                3 && item.is_self == 1) ? '第三方配送' : item.type == 2 ? '打包' : '' }}</div>
                        </td>
                        <td class="t_price" style="width: 150px">
                            <div>{{ item.user_mobile }}</div>
                        </td>
                        <td class="t_price" style="width: 200px">
                            <div>{{ getDateformat(item.update_time) }}</div>
                        </td>
                        <td class="t_price">
                            <div :style="{ color: item.status == 10 ? '#E6A23C' : '#409EFF' }">
                                {{ item.status == -10 || item.status == -20 ? '已取消' : item.status == 0 ? '待支付' : item.status
                                    == 10 ? '待核销' : item.status == 20 ? '已完成' : '--'
                                }}
                            </div>
                        </td>
                        <td class="t_price">
                            <div>{{ item.pick_no }}</div>
                        </td>
                        <td class="caozuo">
                            <div>
                                <el-button type="primary" size="small" v-if="item.status == 10"
                                    @click="finishOrder(item.id)">核销</el-button>
                                <el-button v-if="item.status == 0 || item.status == 10" type="text" size="small"
                                    @click.stop="cancelOrder(item.id)">取消订单</el-button>
                                <el-button v-if="item.status == 0 || item.status == 10" type="text" size="small"
                                    @click.stop="toDetail(item)">订单详情</el-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Paging :total="total" :page="formData.page" :pageNum="formData.rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>
  
<script>
import { getDateformat } from '@/util/getDate.js';
import Paging from '@/components/paging';
/**
 * 搜索订单状态无效
 * 页面功能未完善
 */
export default {
    components: {
        Paging,
    },
    data () {
        return {
            getDateformat,
            formData: {
                page: 1,
                rows: 10,
                order_sn: '',
                mobile: '',
                time: [],
                status: '',
                type: '',
            },
            total: 0,
            list: [],

            showDetail: true
        };
    },
    created () {
        this.getOrder();
    },
    methods: {
        toDetail (item) {
            this.$router.push(`/catering/orderDetail?id=${item.id}`)
        },
        clearSearch () {
            this.formData = {
                page: 1,
                rows: 10,
                state_type: 'all',
                time: [],
                order_no: '',
                receiver_name: '',
                goods_name: '',
                shop_name: '',
                total: 0,
                dataList: [],
            };
            this.getOrder();
        },
        updateData (val, status) {
            if (status == 0) {
                this.formData.rows = val;
            } else {
                this.formData.page = val;
            }
            this.getOrder();
        },
        //取消订单
        cancelOrder (id) {
            this.$confirm('此操作将取消该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$axios.post(this.$api.catering.OrderCancle, { id }).then((res) => {
                        if (res.code == 0) {
                            this.$message.success('取消成功');
                            this.getOrder();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => { });
        },
        getOrder (style) {
            if (style) this.formData.page = 1;
            let obj = { ...this.formData };
            delete obj.time;
            if (this.formData.time?.length) {
                obj.start_time = this.formData.time[0].getTime() / 1000;
                obj.end_time = this.formData.time[1].getTime() / 1000;
                if (obj.start_time == obj.end_time) obj.end_time = obj.end_time + 86400;
            }
            this.$axios.post(this.$api.catering.OrderList, obj).then((res) => {
                if (res.code == 0) {
                    this.total = res.result.total;
                    this.list = res.result.list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getCateringPrice (ordergoods) {
            return ordergoods.reduce((t, v) => {
                return t + v.price * v.goods_num;
            }, 0);
        },
        finishOrder (id) {
            this.$confirm('此操作将核销该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$axios.post(this.$api.catering.OrderFinish, { id }).then((res) => {
                        if (res.code == 0) {
                            this.$message.success('核销成功');
                            this.getOrder();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => { });
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background-color: #fff;

    .order_list {
        width: 100%;
        font-size: 14px;
        overflow-x: auto;

        thead {
            background-color: #f8f9fa;

            th:first-child {
                width: 400px;
            }

            th {
                padding: 15px 0;
                text-align: center;
            }
        }

        .td_box {
            display: flex;
        }

        .order_data_top {
            padding: 5px 20px;
            border-bottom: 1px solid #ebeef5;
            background: #f8f9fa;

            .td_box {
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .order_info {
                    display: flex;
                    align-items: center;

                    .order_no {
                        margin-right: 10px;
                    }

                    .el-icon-info {
                        font-size: 18px;

                        &:hover {
                            color: #409eff;
                        }
                    }
                }
            }
        }

        .goods_info {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 20px;

            &>div {
                display: flex;
                align-items: center;
                height: 70px;
            }

            .goods_name {
                width: 220px;

                .name {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }

            .goods_img {
                width: 50px;
                height: 50px;
                margin-right: 10px;
                border-radius: 5px;
            }
        }

        .p_nums {
            width: 100px;

            &>div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 70px;
            }
        }

        .t_price {
            width: 100px;

            &>div {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .caozuo {
            width: 150px;

            >div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
</style>